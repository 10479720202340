import React from "react";
import classes from "../styles/Hamburger.module.css";

interface LabelProps {
  onClick: () => void;
  scrolled: boolean;
  clicked: boolean;
  children: React.ReactNode;
}

const MenuLabel = ({ onClick, scrolled, clicked, children }: LabelProps) => {
  return (
    <label
      onClick={onClick}
      className={`${classes.iconWrapper} ${scrolled ? classes.scrolled : ""} ${
        clicked ? classes.clicked : ""
      }`}
    >
      {children}
    </label>
  );
};

export default MenuLabel;
