import React from "react";
import classes from "../styles/Hamburger.module.css";
import Box from "@mui/material/Box";

const NavBackground = ({ clicked }: { clicked: boolean }) => {
  return (
    <Box
      component="div"
      className={`${classes.navBg} ${clicked ? classes.clicked : ""}`}
    >
      &nbsp;
    </Box>
  );
};

export default NavBackground;
