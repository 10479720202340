import { TimerProps } from "@/interface/timer";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

const useSaveTheDate = () => {

  const [eventDate] = useState(
    DateTime.fromObject({
      year: 2024,
      month: 5,
      day: 11,
      hour: 16,
      minute: 0,
    }).toFormat("X")
  );

  const [countdownTimer, setCountdownTimer] = useState<TimerProps>({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    let timer: number | undefined | NodeJS.Timer = undefined;

    if (eventDate) {
      timer = setInterval(() => playTimer(parseInt(eventDate)), 1000);
    }

    return () => {
      clearInterval(timer);
      timer = undefined;
    };
  }, [eventDate]);

  const playTimer = (endDate: number) => {
    const now: number = parseInt(DateTime.now().toFormat("X"));

    const distance = endDate - now;

    if (distance > 0) {
      setCountdownTimer({
        days: parseInt((distance / (60 * 60 * 24)).toString(), 10),
        hours: parseInt(
          ((distance % (60 * 60 * 24)) / (60 * 60)).toString(),
          10
        ),
        mins: parseInt(((distance % (60 * 60)) / 60).toString(), 10),
        secs: parseInt((distance % 60).toString(), 10),
      });
    }
  };

  return {
    countdownTimer
  }
}

export default useSaveTheDate