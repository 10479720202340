import React from "react";
import classes from "../styles/Hamburger.module.css";

interface NavProps {
  clicked: boolean;
  children: React.ReactNode;
}
const Navigation = ({ clicked, children }: NavProps) => {
  return (
    <nav
      className={`${classes.navContainer} ${clicked ? classes.clicked : ""}`}
    >
      {children}
    </nav>
  );
};

export default Navigation;
