import { GET_IMAGE } from "@/graphql/queries/images";
import { Image } from "@/interface/image";
import { useLazyQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { useEffect, useState } from "react"

const useLoadedImages = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [getImages] = useLazyQuery(GET_IMAGE);

  useEffect(() => {
    getImages({
      variables: {
        time: DateTime.now()
      },
      onCompleted: (data) => {
        setImages(data.images)
      }
    })
  }, []);

  return {
    images
  }
}

export default useLoadedImages