import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import Link from "next/link";
import { useRouter } from "next/router";

import Icon from "./Icon";
import List from "./List";
import MenuLabel from "./MenuLabel";
import Navigation from "./Navigation";
import { handlee } from "@/utils/fonts";
import NavBackground from "./NavBackground";
import classes from "../styles/Navbar.module.css";
import useBreakpoints from "@/hooks/useBreakpoints";

interface LinkTabProps {
  label: string;
  href: string;
  value: number;
}

const LinkTab = (props: LinkTabProps) => {
  return (
    <Link href={props.href} passHref>
      <Tab label={props.label} value={props.value} />
    </Link>
  );
};

interface ButtonTabProps {
  onClick: (stringRef: string) => void;
  reference: string;
  title: string;
}

const ButtonTab = ({ onClick, reference, title }: ButtonTabProps) => {
  return (
    <Button
      role="tab"
      id={reference}
      aria-describedby={title}
      aria-controls={reference}
      onClick={() => onClick(reference)}
      className={classes.margined}
    >
      <Typography
        className={`${handlee.className} ${classes.buttonLegend}`}
        fontWeight={"600 !important"}
        whiteSpace={"break-spaces"}
      >
        {title}
      </Typography>
    </Button>
  );
};

const ButtonLink = ({ onClick, reference, title }: ButtonTabProps) => {
  return (
    <Button
      id={reference}
      aria-describedby={title}
      aria-controls={reference}
      onClick={() => onClick(reference)}
      className={classes.margined}
    >
      <Typography
        className={`${handlee.className} ${classes.buttonLinkLegend}`}
        fontWeight={"600 !important"}
        whiteSpace={"break-spaces"}
      >
        {title}
      </Typography>
    </Button>
  );
};

interface NavBarProps {
  onClick: (stringRef: string) => void;
}

const Navbar = ({ onClick }: NavBarProps) => {
  const router = useRouter();
  const [tab, setTab] = useState(0);
  const [scrolledNav, setScrolledNav] = useState(false);
  const [click, setClick] = useState(false);

  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  useEffect(() => {
    const pages = [
      "/",
      "/when",
      "/gifts-table",
      "/write-us",
      "/gallery",
      "/confirmation",
      "/nearby",
    ];
    const page = pages.indexOf(router.route);
    setTab(page);
  }, [router]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolledNav(true);
    } else {
      setScrolledNav(false);
    }
  };

  const handleClick = () => setClick(!click);

  return (
    <>
      {tablet || laptop || desktop ? (
        <Box sx={{ width: "100%", position: "fixed", top: 0, zIndex: "10" }}>
          <Tabs
            value={tab}
            centered
            aria-label="Web Navigator"
            sx={{
              background: !scrolledNav
                ? "transparent"
                : "linear-gradient(0.18turn, var(--color-secondary-2-4), var(--color-secondary-2-0), var(--color-secondary-2-3))",
              color: "var(--color-primary-4)",
              // backgroundColor: !scrolledNav ? 'transparent' : '#b19c6f'
            }}
            TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
          >
            <ButtonTab
              onClick={onClick}
              reference="godfather"
              title="Padrinos"
            />
            <ButtonTab onClick={onClick} reference="when" title="Dónde Será" />
            <ButtonTab
              onClick={onClick}
              reference="schedule"
              title="Itinerario"
            />
            <ButtonTab
              onClick={onClick}
              reference="gifts"
              title="Mesa de Regalos"
            />
            <ButtonTab
              onClick={onClick}
              reference="write"
              title="Escribe un Mensaje"
            />
            <ButtonTab onClick={onClick} reference="gallery" title="Galería" />
            <ButtonTab
              onClick={onClick}
              reference="confirmation"
              title="Confirma tu asistencia"
            />
            <ButtonTab
              onClick={onClick}
              reference="nearby"
              title="¿Donde me hospedo?"
            />
            {/* <LinkTab value={0} href="/" label="Inicio" />
              <LinkTab value={1} href="/gifts-table" label="Mesa de Regalos" />
              <LinkTab value={2} href="/write-us" label="Dejanos un mensajito" />
              <LinkTab value={3} href="/gallery" label="Nuestros Momentos" />
              <LinkTab value={4} href="/confirmation" label="Confirma tu Asistencia" />
              <LinkTab value={5} href="/nearby" label="¿Dónde me hospedo?" /> */}
          </Tabs>
        </Box>
      ) : (
        <>
          <MenuLabel
            onClick={handleClick}
            scrolled={scrolledNav}
            clicked={click}
          >
            <Icon clicked={click} />
          </MenuLabel>
          <NavBackground clicked={click} />
          <Navigation clicked={click}>
            <List>
              <ButtonLink
                onClick={() => {
                  onClick("godfather");
                  handleClick();
                }}
                reference="godfather"
                title="Padrinos"
              />

              <ButtonLink
                onClick={() => {
                  onClick("when");
                  handleClick();
                }}
                reference="when"
                title="Dónde Será"
              />

              <ButtonLink
                onClick={() => {
                  onClick("schedule");
                  handleClick();
                }}
                reference="schedule"
                title="Itinerario"
              />

              <ButtonLink
                onClick={() => {
                  onClick("gifts");
                  handleClick();
                }}
                reference="gifts"
                title="Mesa de Regalos"
              />

              <ButtonLink
                onClick={() => {
                  onClick("write");
                  handleClick();
                }}
                reference="write"
                title="Escríbenos un mensaje"
              />

              <ButtonLink
                onClick={() => {
                  onClick("gallery");
                  handleClick();
                }}
                reference="gallery"
                title="Galería"
              />

              <ButtonLink
                onClick={() => {
                  onClick("confirmation");
                  handleClick();
                }}
                reference="confirmation"
                title="Confirma tu asistencia"
              />

              <ButtonLink
                onClick={() => {
                  onClick("nearby");
                  handleClick();
                }}
                reference="nearby"
                title="Dónde hospedarse"
              />
            </List>
          </Navigation>
        </>
      )}
    </>
  );
};

export default Navbar;
