import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import "animate.css";
import styles from "@/styles/Countdown.module.css";
import { ephesis, poiret } from "@/utils/fonts";

interface TimerProps {
  days: number;
  hours: number;
  mins: number;
  secs: number;
}

interface CountdownProps {
  countdownTimer: TimerProps;
}

export const Countdown = ({ countdownTimer }: CountdownProps) => {
  const theme = useTheme();
  const matchesTab = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <>
      {countdownTimer ? (
        <Stack className={styles.countdownContainer}>
          <Stack
            className={matchesTab ? styles.countdownFull : styles.countdownMid}
          >
            <Stack alignItems={"center"}>
              <div className={styles.countdownNumber}>
                <Typography
                  className={`${styles.numberFont} ${poiret.className}`}
                >
                  {countdownTimer.days}
                </Typography>
              </div>
              <Typography
                fontSize={matchesTab ? "6.5vw" : "2vw"}
                fontWeight="700"
                className={ephesis.className}
                // fontFamily="creveland-midleton"
                // fontWeight={"bold"}
                color="var(--color-primary-1)"
                justifyContent="center"
                display="flex"
              >
                Días
              </Typography>
            </Stack>

            <Stack alignItems={"center"}>
              <div className={styles.countdownNumber}>
                <Typography
                  className={`${styles.numberFont} ${poiret.className}`}
                >
                  {countdownTimer.hours < 10
                    ? `0${countdownTimer.hours}`
                    : countdownTimer.hours}
                </Typography>
              </div>
              <Typography
                fontSize={matchesTab ? "6.5vw" : "2vw"}
                fontWeight="700"
                className={ephesis.className}
                color="var(--color-primary-1)"
                justifyContent="center"
                display="flex"
              >
                Horas
              </Typography>
            </Stack>

            <Stack alignItems={"center"}>
              <div className={styles.countdownNumber}>
                <Typography
                  className={`${styles.numberFont} ${poiret.className}`}
                >
                  {countdownTimer.mins < 10
                    ? `0${countdownTimer.mins}`
                    : countdownTimer.mins}
                </Typography>
              </div>
              <Typography
                fontSize={matchesTab ? "6.5vw" : "2vw"}
                fontWeight="700"
                className={ephesis.className}
                color="var(--color-primary-1)"
                justifyContent="center"
                display="flex"
              >
                Minutos
              </Typography>
            </Stack>

            <Stack alignItems={"center"}>
              <div
                className={`${styles.heart} animate__animated animate__pulse animate__infinite`}
                style={{ zIndex: "1", marginBottom: "10px" }}
              >
                <div className={styles.countdownHeartContainer}>
                  <Typography
                    className={`${styles.heartNumberFont} ${poiret.className}`}
                  >
                    {countdownTimer.secs < 10
                      ? `0${countdownTimer.secs}`
                      : countdownTimer.secs}
                  </Typography>
                </div>
              </div>
              <Typography
                fontSize={matchesTab ? "6.5vw" : "2vw"}
                fontWeight="700"
                className={ephesis.className}
                color="var(--color-primary-1)"
              >
                Segundos
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : null}
    </>
  );
};
