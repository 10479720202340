import { useEffect, useState } from "react";
import { CupertinoPane } from "cupertino-pane";
import Typography from "@mui/material/Typography";
import { handlee } from "@/utils/fonts";

const Music = () => {
  const [drawer, setDrawer] = useState<CupertinoPane>();

  useEffect(() => {
    if (!drawer)
      setDrawer(
        new CupertinoPane(
          ".cupertinoPane", // Pane container selector
          {
            buttonDestroy: false,
            draggableOver: true,
            breaks: {
              middle: { enabled: true, height: 450, bounce: true },
              bottom: { enabled: true, height: 48 },
            },
            initialBreak: "bottom",
          }
        )
      );
    else {
      setTimeout(() => drawer.present({ animate: true }));
    }
  }, [drawer]);

  return (
    <div
      className={"cupertinoPane"}
      style={{
        borderBottom: "12px",
      }}
    >
      <Typography
        className={handlee.className}
        textAlign={"center"}
        color={"var(--color-primary-4)"}
        fontWeight={"600 !important"}
      >
        Conoce la música de esta historia
      </Typography>
      <iframe
        style={{
          marginTop: "1rem",
          border: "none",
          borderRadius: "12px",
        }}
        src="https://open.spotify.com/embed/playlist/1NHCf6eemQOLORBPjHCoif?utm_source=generator&theme=0"
        width="100%"
        height={400}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="Spotify Embed"
      />
    </div>
  );
};

export default Music;
