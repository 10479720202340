import React from "react";
import classes from "../styles/Hamburger.module.css";

const Icon = ({ clicked }: { clicked: boolean }) => {
  return (
    <span className={`${classes.icon} ${clicked ? classes.clicked : ""}`}>
      &nbsp;
    </span>
  );
};

export default Icon;
