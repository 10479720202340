import dynamic from "next/dynamic";
import { useRef } from "react";

import Godfathers from "./godfathers";
import SectionText from "@/components/SectionText";
import Navbar from "@/components/Navbar";
import useApprovedMessages from "@/hooks/useApprovedMessages";
import useLoadedImages from "@/hooks/useLoadedImages";
import useBreakpoints from "@/hooks/useBreakpoints";
import SaveTheDate from "./save-the-date";
import Music from "./music/Music";

const When = dynamic(() => import("./when"));
const Schedule = dynamic(() => import("./schedule"));
const GiftsTable = dynamic(() => import("./gifts-table"));
const WriteUs = dynamic(() => import("./write-us"));
const Gallery = dynamic(() => import("./gallery"));
const Confirmation = dynamic(() => import("./confirmation"));
const Nearby = dynamic(() => import("./nearby"));

export default function Home() {
  const { messages } = useApprovedMessages();
  const { images } = useLoadedImages();
  const { smartphone, mobile, tablet } = useBreakpoints();

  const when = useRef<null | HTMLElement>(null);
  const giftsTable = useRef<null | HTMLElement>(null);
  const writeUs = useRef<null | HTMLElement>(null);
  const gallery = useRef<null | HTMLElement>(null);
  const confirmation = useRef<null | HTMLElement>(null);
  const nearby = useRef<null | HTMLElement>(null);
  const godfather = useRef<null | HTMLElement>(null);
  const schedule = useRef<null | HTMLElement>(null);

  const handleRefClick = (stringRef: string) => {
    debugger;
    switch (stringRef) {
      case "when":
        window.scrollTo({
          top: when.current?.offsetTop
            ? smartphone || mobile || tablet
              ? when.current.offsetTop
              : when.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;
      case "godfather":
        window.scrollTo({
          top: godfather.current?.offsetTop
            ? smartphone || mobile || tablet
              ? godfather.current.offsetTop
              : godfather.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;
      case "schedule":
        window.scrollTo({
          top: schedule.current?.offsetTop
            ? smartphone || mobile || tablet
              ? schedule.current.offsetTop
              : schedule.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;
      case "gifts":
        window.scrollTo({
          top: giftsTable.current?.offsetTop
            ? smartphone || mobile || tablet
              ? giftsTable.current.offsetTop
              : giftsTable.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;
      case "write":
        window.scrollTo({
          top: writeUs.current?.offsetTop
            ? smartphone || mobile || tablet
              ? writeUs.current.offsetTop
              : writeUs.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;
      case "gallery":
        window.scrollTo({
          top: gallery.current?.offsetTop
            ? smartphone || mobile || tablet
              ? gallery.current.offsetTop
              : gallery.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;
      case "confirmation":
        window.scrollTo({
          top: confirmation.current?.offsetTop
            ? smartphone || mobile || tablet
              ? confirmation.current.offsetTop
              : confirmation.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;
      case "nearby":
        window.scrollTo({
          top: nearby.current?.offsetTop
            ? smartphone || mobile || tablet
              ? nearby.current.offsetTop
              : nearby.current.offsetTop - 48
            : 0,
          behavior: "smooth",
        });
        break;

      default:
        "default";
    }
  };

  return (
    <>
      <Navbar onClick={handleRefClick} />

      <SaveTheDate />

      <section ref={godfather}>
        <SectionText title="Nuestros Padres y Padrinos" pContent="" />
        <Godfathers />
      </section>

      <section ref={when}>
        <SectionText
          title="¿Cuándo y Dónde?"
          pContent="Este nos pareció el lugar indicado para ese día tan especial"
        />
        <When />
      </section>

      <section ref={schedule}>
        <SectionText title="Itinerario" pContent="" />
        <Schedule />
      </section>

      <section ref={giftsTable}>
        <SectionText
          title="Mesa de Regalos"
          pContent="Gracias por apoyarnos en esta nueva etapa"
        />
        <GiftsTable />
      </section>

      <section ref={writeUs}>
        <SectionText
          title="Escríbenos un Mensaje"
          pContent="Déjanos tus mejores deseos en la cajita"
        />
        <WriteUs messages={messages} />
      </section>

      <section ref={gallery}>
        <SectionText
          title="Compartamos momentos"
          pContent="Conoce un poquito de nuestra historia o sube tu foto"
        />
        <Gallery imageData={images} />
      </section>

      <section ref={confirmation}>
        <SectionText title="Confirmanos tu asistencia" pContent="" />
        <Confirmation />
      </section>

      <section ref={nearby}>
        <SectionText
          title="¿Dónde me hospedo?"
          pContent="Aquí te dejamos algunas recomendaciones de hospedaje"
        />
        <Nearby />
      </section>

      <Music />
    </>
  );
}
