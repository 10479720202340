import { gql } from "@apollo/client";

export const GET_IMAGE = gql`
  query getImages {
    images {
      id
      publicId
      url
      height
    }
  }
`