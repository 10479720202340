import { GET_MESSAGES } from "@/graphql/queries/messages"
import { Messages } from "@/interface/message";
import { useLazyQuery } from "@apollo/client"
import { useEffect, useState } from "react"

const useApprovedMessages = () => {
  
  const [messages, setMessages] = useState<Messages[]>([]);
  const [getMessages] = useLazyQuery(GET_MESSAGES)

  useEffect(() => {
    getMessages({
      onCompleted: (data) => {
        (data);
        setMessages(data.messages)
      }
    })
  }, [])
  
  return {
    messages
  }
}

export default useApprovedMessages