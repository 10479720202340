import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ephesis, poiret } from "@/utils/fonts";
import classes from "../styles/SectionText.module.css";

interface SectionTextProps {
  title: string;
  pContent: string;
}

const SectionText = ({ title, pContent }: SectionTextProps) => {
  return (
    <Box className={classes.container}>
      <Typography
        className={ephesis.className}
        fontSize={"10vh"}
        paddingX={"2vw"}
        fontWeight={"bolder"}
      >
        R
      </Typography>

      <Stack>
        <Typography
          variant="h3"
          textAlign="center"
          className={`${classes.sectionTitle} ${ephesis.className}`}
        >
          {title}
        </Typography>
        <Typography
          className={`${classes.sectionParagraph} ${poiret.className}`}
        >
          {pContent}
        </Typography>
      </Stack>

      <Typography
        className={ephesis.className}
        fontSize={"10vh"}
        paddingX={"2vw"}
        fontWeight={"bolder"}
      >
        A
      </Typography>
    </Box>
  );
};

export default SectionText;
