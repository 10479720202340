import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ephesis } from "@/utils/fonts";
import { Countdown } from "@/components/Countdown";
import useBreakpoints from "@/hooks/useBreakpoints";
import useSaveTheDate from "@/hooks/useSaveTheDate";

const SaveTheDate = () => {
  const { countdownTimer } = useSaveTheDate();
  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Box
      style={{
        backgroundImage:
          smartphone || mobile || tablet
            ? "url(/anillo_playa_mobile.webp)"
            : "url(/aca_desk.webp)",
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Stack
        sx={{ width: "100%", height: "100%", justifyContent: "space-around" }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.1 }}
          whileInView={{ opacity: 1, scale: 1 }}
          // animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            fontSize={
              smartphone
                ? "15vw"
                : mobile
                ? "15vw"
                : tablet
                ? "10vw"
                : laptop
                ? "8vw"
                : desktop
                ? "8vw"
                : "1vw"
            }
            fontWeight={"700"}
            className={ephesis.className}
            color="var(--color-primary-1)"
            justifyContent="center"
            display="flex"
          >
            Rosa & Adrián
          </Typography>

          <Stack>
            <Typography
              fontSize={
                smartphone
                  ? "13vw"
                  : mobile
                  ? "13vw"
                  : tablet
                  ? "6vw"
                  : laptop
                  ? "4vw"
                  : desktop
                  ? "4vw"
                  : "1vw"
              }
              fontWeight={"700"}
              className={ephesis.className}
              color="var(--color-primary-1)"
              justifyContent="center"
              display="flex"
            >
              Save the date
            </Typography>

            <Typography
              fontSize={
                smartphone
                  ? "12vw"
                  : mobile
                  ? "12vw"
                  : tablet
                  ? "5vw"
                  : laptop
                  ? "3vw"
                  : desktop
                  ? "3vw"
                  : "1vw"
              }
              fontWeight={"700"}
              className={ephesis.className}
              color="var(--color-primary-1)"
              justifyContent="center"
              display="flex"
            >
              11/05/2024
            </Typography>
          </Stack>
        </motion.div>
        <Countdown countdownTimer={countdownTimer} />
      </Stack>
    </Box>
  );
};

export default SaveTheDate;
